/* global widgetInit */
import _ from 'lodash'
import { createSelector } from 'reselect'

export const selectVenueGroupMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_venue_group_policy

export const selectVenueSpecificMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_policy

export const selectVenueSmsMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_sms_policy

export const selectReservationSmsOptInPolicy = () => widgetInit.venueInfo.reservation_sms_opt_in_policy
const selectedLanguageCode = () => widgetInit.venueInfo.selected_language_code
const paymentPolicyMap = () => widgetInit.venueInfo.payment_policy_map

const getUserSelection = state => state.userSelection

const getInventoryEntities = state => state.entities.inventory

const getEventEntities = state => state.entities.events

export const selectBookingPolicy = createSelector(
  [getUserSelection, getInventoryEntities, getEventEntities, paymentPolicyMap, selectedLanguageCode],
  (userSelection, inventoryEntities, eventEntities, paymentPolicyMap, selectedLanguageCode) => {
    const { eventId, inventoryItems } = userSelection.toJS()
    const { inventoryId } = inventoryItems[0]
    const currInventory = inventoryEntities.get(inventoryId)
    const selectedEvent = eventEntities.toJS()[eventId]

    if (currInventory.get('customCancellationPolicy')) {
      return currInventory.get('customCancellationPolicy')
    }

    const inventoryCancellationPolicyId = currInventory.get('cancellationPolicyId')
    if (paymentPolicyMap && inventoryCancellationPolicyId) {
      if (paymentPolicyMap[inventoryCancellationPolicyId]) {
        return paymentPolicyMap[inventoryCancellationPolicyId][selectedLanguageCode]
      }
      return _.values(paymentPolicyMap)[0][selectedLanguageCode]
    }

    if (selectedEvent.customCancellationPolicy) {
      return selectedEvent.customCancellationPolicy
    }

    const eventCancellationPolicyId = selectedEvent.cancellationPolicyId
    if (paymentPolicyMap && eventCancellationPolicyId) {
      if (paymentPolicyMap[eventCancellationPolicyId]) {
        return paymentPolicyMap[eventCancellationPolicyId][selectedLanguageCode]
      }

      return _.values(paymentPolicyMap)[0][selectedLanguageCode]
    }

    return widgetInit.venueInfo.event_widget_purchase_policy
  }
)
