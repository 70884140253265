/* eslint react/prop-types: 0, react/jsx-no-bind: 0, jsx-a11y/no-static-element-interactions: 0 */
import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import { AccessibleDivComponent } from 'svr/common/A11y'
import * as styles from '../assets/styles/eventOverview'
import QuantityStepper from '../containers/QuantityStepper'
import { connect, useDispatch } from 'react-redux'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import { handleHover } from 'widget/events/InteractiveFloorplan/actions'
import { calcTotalFeePrice } from 'widget/events/utils/calcCharges'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

export const InventoryItemSmall = ({
  availabilityId,
  inventoryItem,
  inventoryEdits,
  isHovered,
  boundToggleInventoryItem,
  selectedDate,
  selectInventoryItem,
  isFloorplan,
  currencyCode,
  textMinimumSpend,
  isFeesInPriceDisplayed,
  defaultServiceCharge,
}) => {
  const dispatch = useDispatch()
  const highlightedInventoryRef = useRef()
  const { price, compText, inventoryId, inventoryType, entryPerReservation } = inventoryItem
  const maxQuantity = Math.min(inventoryItem.maxQuantity, inventoryEdits[inventoryId].remainingQuantity)
  const { isHighlighted } = inventoryEdits[inventoryId]
  const quantity = Math.max(inventoryItem.minQuantity, inventoryEdits[inventoryId].quantity)
  const basePrice = price * quantity

  const totalFeePrice = calcTotalFeePrice(inventoryItem, quantity, price, defaultServiceCharge)
  const totalPrice = isFeesInPriceDisplayed ? basePrice + totalFeePrice : basePrice

  const numGuests = quantity * entryPerReservation
  const minDollarText = (function () {
    if (!inventoryItem.minPrice) {
      return ''
    }
    const minDollarAmount = quantity * inventoryItem.minPrice
    const minDollarAmountFormatted = priceFormatter.format(minDollarAmount, {
      code: currencyCode,
    })
    return `, ${minDollarAmountFormatted} ${textMinimumSpend}`
  })()

  let headerText
  if (numGuests === 1) {
    const headerInner = inventoryType === 'RESERVATION' ? `For 1 guest${minDollarText}` : 'For 1 guest'
    headerText = <span>{headerInner}</span>
  } else {
    let headerString
    if (inventoryType === 'RESERVATION') {
      headerString = `Up to ${numGuests} guests${minDollarText}`
    } else if (inventoryType === 'REQUEST') {
      headerString = ''
    } else {
      headerString = `For ${numGuests} guests`
    }
    headerText = <span>{headerString}</span>
  }

  const onInventoryClick = () => {
    if ((maxQuantity === 1 || inventoryType === 'REQUEST') && !isFloorplan) {
      selectInventoryItem(inventoryId)
    } else {
      boundToggleInventoryItem(inventoryId)
    }
  }

  let priceText = priceFormatter.format(totalPrice, { code: currencyCode })
  if (inventoryType === 'GUESTLIST_TICKET_FREE') {
    priceText = compText
  } else if (inventoryType === 'REQUEST') {
    priceText = 'Request'
  }

  useEffect(() => {
    if (isFloorplan && isHighlighted && highlightedInventoryRef.current) {
      scrollIntoView(highlightedInventoryRef.current, { behavior: 'smooth', block: 'center', inline: 'center' })
    }
  }, [isFloorplan, isHighlighted, inventoryItem.inventoryName])

  const highlightedItem = (
    <AccessibleDivComponent aria-label="inventory item" onClick={onInventoryClick} style={styles.getHighlightedListItem(isFloorplan)}>
      <div ref={highlightedInventoryRef} style={styles.headerTextSmall}>
        {headerText}
      </div>
      <div style={_.assign({}, styles.labelText, { width: '70%' })}>{inventoryItem.inventoryName}</div>
      <div style={styles.priceBoxSmall}>
        <span style={styles.priceText} data-test="price-text">
          {priceText}
        </span>
        {!!(isFeesInPriceDisplayed && totalFeePrice) && (
          <span data-test="fees-in-price" style={styles.feesText}>
            * Includes {priceFormatter.format(totalFeePrice, { code: currencyCode })} in fees
          </span>
        )}
      </div>
      <div style={styles.continueButtonWrapper}>
        {maxQuantity > 1 ? (
          <QuantityStepper
            inventoryId={inventoryId}
            quantity={quantity}
            minQuantity={inventoryItem.minQuantity}
            maxQuantity={maxQuantity}
            styleObj={{ margin: '8px 0 0 0', clear: 'left' }}
          />
        ) : (
          // empty div so styles.continueButtonWrapper {justifyContent: space-between} always align continue btn to the right
          <div />
        )}
        <AccessibleDivComponent
          aria-label="select inventory"
          onClick={selectInventoryItem.bind(null, inventoryId, availabilityId)}
          style={_.assign({}, styles.continueButton, {
            width: '39.58944%',
            marginTop: '10px',
            marginRight: '0',
          })}
        >
          continue
        </AccessibleDivComponent>
      </div>
    </AccessibleDivComponent>
  )

  const labelTextStyle =
    inventoryType === 'REQUEST'
      ? _.assign({}, styles.labelText, { width: '70%', marginTop: '-9px' })
      : _.assign({}, styles.labelText, { width: '74%' })
  const priceBoxRequestStyle = inventoryType === 'REQUEST' ? _.assign({}, styles.priceBoxSmall, { width: 'auto' }) : styles.priceBoxSmall
  const unHighlightedItem = (
    <AccessibleDivComponent
      aria-label="inventory item"
      data-inventory-item-name={inventoryItem.inventoryName}
      onClick={onInventoryClick}
      onMouseOver={() => dispatch(handleHover({ inventoryName: inventoryItem.inventoryName, hover: true, selectedDate }))}
      onMouseOut={() => dispatch(handleHover({ inventoryName: inventoryItem.inventoryName, hover: false, selectedDate }))}
      style={_.assign({}, styles.getSmallInventoryListItem(isHovered), styles.largeDevicePadding)}
    >
      <div style={styles.headerTextSmall} data-test="header-text-small">
        {headerText}
      </div>
      <div style={labelTextStyle}>{inventoryItem.inventoryName}</div>
      <div style={priceBoxRequestStyle}>
        <span style={styles.priceText} data-test="price-text">
          {priceText}
        </span>
        {!!(isFeesInPriceDisplayed && totalFeePrice) && (
          <span data-test="fees-in-price" style={styles.feesText}>
            * Includes {priceFormatter.format(totalFeePrice, { code: currencyCode })} in fees
          </span>
        )}
      </div>
    </AccessibleDivComponent>
  )

  return isHighlighted ? highlightedItem : unHighlightedItem
}
const mapStateToProps = (state, ownProps) => {
  const { date, eventId } = state.userSelection.toJS()
  return {
    eventAvailabilities: state.entities.availability.get(date).get(eventId),
    selectedDate: state.userSelection.get('date'),
    textMinimumSpend: state.widgetSettings.textMinimumSpend,
    isHovered:
      ownProps.isFloorplan &&
      state.interactiveFloorplan.svgInventory[state.userSelection.get('date')]?.find(
        ({ inventoryName, status }) => status === 'hover' && inventoryName === ownProps.inventoryItem.inventoryName
      ),
  }
}
const mergeProps = (stateProps, _dispatchProps, ownProps) => {
  const { inventoryId } = ownProps.inventoryItem
  const availabilityId = stateProps.eventAvailabilities.get(inventoryId).get('availabilityId')

  return {
    ...stateProps,
    ...ownProps,
    availabilityId,
  }
}
export default connect(mapStateToProps, null, mergeProps)(InventoryItemSmall)
