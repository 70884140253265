import { GET_EVENTS_SUCCESS, INCREMENT_ITEM, DECREMENT_ITEM, SELECT_INVENTORY_ITEM, UPDATE_PRICES } from '../actions/ActionTypes'

interface InventoryCartState {
  cart: {
    inventoryId: string
    quantity: number
    basePrice?: string
    discountedPrice?: number
    tax?: string
    taxPercent?: number
    chargeTax?: boolean
    serviceCharge?: number
    serviceChargePercent?: number
    additionalFeeAmount?: number
    additionalFeeTaxAmount?: number
    additionalFeeTaxPercent?: number
    tip?: string
    subTotal?: string
    total?: string
  }[]
}

interface Inventory {
  inventoryId: string
  minQuantity: number
}

interface InventoryCartStateAction {
  type: string
  date?: string
  eventId?: string
  inventoryId?: string
  data?: {
    inventory: { [key: string]: Inventory }
  }
  pricingDetails?: {
    basePrice: string
    discountedPrice: number
    tax: string
    taxPercent?: number
    chargeTax?: boolean
    serviceCharge: number
    serviceChargePercent: number
    additionalFeeAmount: number
    additionalFeeTaxAmount: number
    additionalFeeTaxPercent: number
    tip: string
    subTotal: string
    total: string
  }
}

const inventoryCart = (state: InventoryCartState = { cart: [] }, action: InventoryCartStateAction) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS: {
      const { data } = action

      if (!data?.inventory) {
        return state
      }

      const cart: InventoryCartState['cart'] = []
      Object.values(data.inventory).forEach(({ inventoryId, minQuantity }) => {
        cart.push({ inventoryId, quantity: minQuantity })
      })

      return {
        ...state,
        cart,
      }
    }
    case INCREMENT_ITEM: {
      const { inventoryId } = action
      return {
        ...state,
        cart: state.cart.map(item => (item.inventoryId === inventoryId ? { ...item, quantity: item.quantity + 1 } : item)),
      }
    }
    case DECREMENT_ITEM: {
      const { inventoryId } = action
      return {
        ...state,
        cart: state.cart.map(item => (item.inventoryId === inventoryId ? { ...item, quantity: item.quantity - 1 } : item)),
      }
    }
    case SELECT_INVENTORY_ITEM:
    case UPDATE_PRICES: {
      return {
        ...state,
        cart: state.cart.map(item => {
          if (!Array.isArray(action.pricingDetails)) {
            return state
          }

          const pricingDetail = action.pricingDetails.find(detail => detail.inventoryId === item.inventoryId) || {}
          return {
            ...item,
            ...pricingDetail,
          }
        }),
      }
    }
    default:
      return state
  }
}

export default inventoryCart
